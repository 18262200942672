/**
 *
 * @param {JsonObject} theme Theme customization object
 */

const themePalette = (mode, theme) => ({
    ...{
        common: {
            black: theme.colors?.darkPaper
        },
        primary: {
            light: theme.colors?.primaryLight,
            main: theme.colors?.primaryMain,
            dark: theme.colors?.primaryDark,
            extradark: theme.colors?.primaryExtraDark,
            auth: theme.colors?.grey0,
            saturated: theme.colors?.primarySaturated,
            200: theme.colors?.primary200,
            600: theme.colors?.primary600,
            800: theme.colors?.primary800
        },
        secondary: {
            light: theme.colors?.secondaryLight,
            main: mode === 'light' ? theme.colors?.secondaryMain : theme.colors?.secondaryAltMain,
            dark: theme.colors?.secondaryDark,
            200: theme.colors?.secondary200,
            800: theme.colors?.secondary800
        },
        error: {
            light: theme.colors?.errorLight,
            main: mode === 'light' ? theme.colors?.errorMain : theme.colors?.errorAltMain,
            dark: theme.colors?.errorDark
        },
        orange: {
            light: theme.colors?.orangeLight,
            main: mode === 'light' ? theme.colors?.secondaryMain : theme.colors?.secondaryAltMain,
            dark: theme.colors?.orangeDark
        },
        warning: {
            light: theme.colors?.warningLight,
            main: mode === 'light' ? theme.colors?.secondaryMain : theme.colors?.secondaryAltMain,
            dark: theme.colors?.warningDark
        },
        success: {
            light: theme.colors?.successLight,
            200: theme.colors?.success200,
            main: theme.colors?.successMain,
            dark: theme.colors?.successDark
        },
        grey: {
            0: theme.colors?.grey0,
            50: theme.colors?.grey50,
            100: theme.colors?.grey100,
            200: theme.colors?.grey200,
            300: theme.colors?.grey300,
            400: theme.colors?.grey400,
            500: theme.colors?.grey500,
            600: theme.colors?.grey600,
            700: theme.colors?.grey700,
            800: theme.colors?.grey800,
            900: theme.colors?.grey900
        },
        dark: {
            light: theme.colors?.darkTextPrimary,
            main: theme.colors?.darkLevel1,
            dark: theme.colors?.darkLevel2,
            800: theme.colors?.darkBackground,
            900: theme.colors?.darkPaper
        },
        text: {
            primary: theme.darkTextPrimary,
            secondary: mode === 'dark' ? theme.colors?.darkTextPrimary : theme.colors?.grey500,
            dark: theme.textDark,
            hint: mode === 'dark' ? theme.colors?.grey900 : theme.colors?.grey100
        },
        background: {
            paper: theme.paper,
            default: theme.backgroundDefault
        },
        reverse: {
            extralight: mode === 'dark' ? theme.colors?.primaryExtraDark : theme.colors?.primaryLight,
            white: mode === 'dark' ? theme.colors?.primaryExtraDark : theme.colors?.grey0,
            light: mode === 'dark' ? theme.colors?.primaryDark : theme.colors?.primaryLight,
            main: mode === 'dark' ? theme.colors?.primary600 : theme.colors?.primaryMain,
            dark: mode === 'dark' ? theme.colors?.primaryLight : theme.colors?.primaryDark,
            light200: mode === 'dark' ? theme.colors?.primary800 : theme.colors?.primary200,
            extradark: mode === 'dark' ? theme.colors?.grey0 : theme.colors?.primaryExtraDark,
            middark: mode === 'dark' ? theme.colors?.primaryLight : theme.colors?.primaryMidDark,
            secondaryLight: mode === 'dark' ? theme.colors?.secondaryDark : theme.colors?.secondaryLight,
            secondaryMain: mode === 'dark' ? theme.colors?.secondaryMain : theme.colors?.secondary800,
            secondaryDark: mode === 'dark' ? theme.colors?.secondary800 : theme.colors?.secondaryDark,
            0: mode === 'dark' ? theme.colors?.grey0 : theme.colors?.grey900,
            50: mode === 'dark' ? theme.colors?.grey50 : theme.colors?.grey800,
            100: mode === 'dark' ? theme.colors?.grey100 : theme.colors?.grey700,
            200: mode === 'dark' ? theme.colors?.grey200 : theme.colors?.grey600,
            300: mode === 'dark' ? theme.colors?.grey300 : theme.colors?.grey500,
            400: mode === 'dark' ? theme.colors?.grey400 : theme.colors?.grey400,
            500: mode === 'dark' ? theme.colors?.grey500 : theme.colors?.grey300,
            600: mode === 'dark' ? theme.colors?.grey600 : theme.colors?.grey200,
            700: mode === 'dark' ? theme.colors?.grey700 : theme.colors?.grey100,
            800: mode === 'dark' ? theme.colors?.grey800 : theme.colors?.grey50,
            900: mode === 'dark' ? theme.colors?.grey900 : theme.colors?.grey0
        }
    }
});

export default themePalette;
