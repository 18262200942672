// assets
import {
    IconBorderAll,
    IconColumns,
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconListDetails,
    IconBrandTabler
} from '@tabler/icons';

// constant
const icons = {
    IconBorderAll,
    IconColumns,
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconListDetails,
    IconBrandTabler
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Components',
    type: 'group',
    children: [
        {
            id: 'util-typography',
            title: 'Typography',
            type: 'item',
            url: '/utils/util-typography',
            icon: icons.IconTypography,
            breadcrumbs: false
        },
        {
            id: 'util-color',
            title: 'Color',
            type: 'item',
            url: '/utils/util-color',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-button',
            title: 'Buttons',
            type: 'item',
            url: '/utils/util-button',
            icon: icons.IconBrandTabler,
            breadcrumbs: false
        },
        {
            id: 'util-form',
            title: 'Form Elements',
            type: 'item',
            url: '/utils/util-form',
            icon: icons.IconListDetails,
            breadcrumbs: false
        },
        {
            id: 'table-patterns',
            title: 'Table Patterns',
            type: 'item',
            url: '/table-patterns',
            icon: icons.IconBorderAll,
            breadcrumbs: false
        },
        {
            id: 'data-grid-patterns',
            title: 'Data Grid Patterns',
            type: 'item',
            url: '/data-grid-patterns',
            icon: icons.IconColumns,
            breadcrumbs: false
        },
        {
            id: 'icons',
            title: 'Icons',
            type: 'collapse',
            icon: icons.IconWindmill,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Tabler Icons',
                    type: 'item',
                    url: '/icons/tabler-icons',
                    breadcrumbs: false
                },
                {
                    id: 'material-icons',
                    title: 'Material Icons',
                    type: 'item',
                    url: '/icons/material-icons',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default utilities;
