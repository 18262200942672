// material-ui
import { useTheme } from '@mui/material/styles';

import logoDark from 'assets/images/logo-dark.svg';
import logo from 'assets/images/logo.svg';

// ==============================|| LOGO SVG ||============================== //

// Fix this later

const Logo = () => {
    const theme = useTheme();

    return (
        <>
            {/* <img src={logo} alt="BagsID" width="160" /> */}
            <img src={logoDark} alt="BagsID" width="160" />
        </>
    );
};

export default Logo;
