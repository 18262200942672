import { alpha, createTheme } from '@mui/material/styles';

// assets
import colors from 'assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
    const color = colors;

    // Fix this later
    const mode = 'dark';

    const themeOption =
        mode === 'light'
            ? {
                  colors: color,
                  paper: color.paper,
                  backgroundDefault: color.paper,
                  fieldBackground: color.paper,
                  fieldSelectBackground: color.primaryLight,
                  background: color.primaryLight,
                  darkTextPrimary: color.grey800,
                  darkTextSecondary: color.grey500,
                  darkTextLink: color.primaryMain,
                  primaryMedium: color.grey600,
                  primarySaturated: color.primaryMain,
                  secondaryHeading: color.secondaryMain,
                  largeHeading: color.primaryMain,
                  miniHeading: color.grey800,
                  textDark: color.grey900,
                  menuSelectedBack: color.primaryLight,
                  menuSelected: color.primaryDark,
                  cardBackground: color.primaryMain,
                  secondary: color.secondary800,
                  successMain: color.successMain,
                  errorMain: color.errorMain,
                  divider: color.grey200,
                  fieldBorder: color.grey800,

                  primaryShadow: `0px 12px 14px 0px ${alpha(color.primaryMain, 0.3)}`,
                  secondaryShadow: `0px 12px 14px 0px ${alpha(color.secondaryMain, 0.3)}`,
                  orangeShadow: `0px 12px 14px 0px ${alpha(color.secondaryMain, 0.3)}`,
                  succesShadow: `0px 12px 14px 0px ${alpha(color.successMain, 0.3)}`,
                  warningShadow: `0px 12px 14px 0px ${alpha(color.secondaryMain, 0.3)}`,
                  errorShadow: `0px 12px 14px 0px ${alpha(color.errorMain, 0.3)}`,

                  customization
              }
            : {
                  colors: color,
                  paper: color.primaryDark,
                  backgroundDefault: color.primaryExtraDark,
                  background: color.primaryMidDark,
                  fieldBackground: color.primaryMidDark,
                  fieldSelectBackground: color.primaryMidDark,
                  darkTextPrimary: color.grey200,
                  darkTextSecondary: color.grey500,
                  darkTextLink: color.primary200,
                  primaryMedium: color.primary600,
                  primarySaturated: color.primarySaturated,
                  secondaryHeading: color.secondary800,
                  largeHeading: color.primaryLight,
                  miniHeading: color.grey200,
                  textDark: color.grey50,
                  menuSelectedBack: color.primaryLight,
                  menuSelected: color.primaryDark,
                  cardBackground: color.primaryMain,
                  secondary: color.secondary200,
                  successMain: color.successAltMain,
                  errorMain: color.errorAltMain,
                  divider: color.primaryMain,
                  fieldBorder: color.primary200,

                  primaryShadow: `0px 12px 14px 0px ${alpha(color.primaryMain, 0.3)}`,
                  secondaryShadow: `0px 12px 14px 0px ${alpha(color.secondaryMain, 0.3)}`,
                  orangeShadow: `0px 12px 14px 0px ${alpha(color.secondaryMain, 0.3)}`,
                  succesShadow: `0px 12px 14px 0px ${alpha(color.successMain, 0.3)}`,
                  warningShadow: `0px 12px 14px 0px ${alpha(color.secondaryMain, 0.3)}`,
                  errorShadow: `0px 12px 14px 0px ${alpha(color.errorMain, 0.3)}`,

                  customization
              };

    const themeOptions = {
        direction: 'ltr',
        palette: themePalette(mode, themeOption),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
        typography: themeTypography(themeOption)
    };

    const themes = createTheme(themeOptions);
    themes.components = componentStyleOverrides(mode, themeOption);

    return themes;
};

export default theme;
